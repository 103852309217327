<template>
  <div>
    <v-card 
      v-if="refinedList.length" 
      :color="pendings.length ? 'yellow lighten-4' : 'green lighten-5'"
      class="mb-5" 
      flat
    >
      <v-card-text>
        <p class="font-weight-bold d-flex align-center">
          <v-icon left small>
            {{ pendings.length ? 'mdi-alert-circle-outline' : 'mdi-check' }}
          </v-icon>
          {{ pendings.length ? 'Please complete the following:' : 'All required actions completed' }}
        </p>

        <v-checkbox 
          v-for="list in refinedList"
          :key="list.id"
          :value="list.done"
          :input-value="list.done"
          class="mt-0"
          @change="updateValue(list, $event)"
          hide-details
          dense
        >
          <template #label>
            <span 
              :style="{ fontSize: '0.8rem'}"
              :class="{ 'text-decoration-line-through' : list.done }"
            >
              {{ list.text }}
            </span>
          </template>

          <template v-if="user.role == 'admin'" #append>
            <v-btn
              @click="$store.commit('actionList/setData', list)"
              icon 
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            
            <v-btn 
              @click="deleteList(list)"
              :loading="status.deleting == list.id"
              icon 
              small
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-checkbox>
      </v-card-text>
    </v-card>

    <v-form v-if="user.role == 'admin'" ref="form" @submit.prevent="validateForm()">
      <v-textarea
        v-model="data.text"
        placeholder="Type then press 'Enter'"
        :loading="status.adding"
        :disabled="status.adding"
        :rules="[rules.required]"
        @keydown.enter="validateForm()"
        outlined
        rows="2"
      ></v-textarea>
    </v-form>

    <v-btn 
      v-if="user.role == 'admin'"
      @click="approve()"
      :disabled="pendings.length > 0"
      :loading="recipeStatus.approving"
      color="primary" 
      class="mr-2" 
      depressed
    >Approve</v-btn>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import rules from '@/rules'
import { orderBy } from 'lodash'

export default {
  props: {
    recipe: {
      type: Object,
      required: true,
    }
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      lists: state => state.actionList.lists,
      data: state => state.actionList.data,
      status: state => state.actionList.status,
      user: state => state.user.user,
      recipeStatus: state => state.recipes.status
    }),

    refinedList: function () {
      return orderBy(this.lists, 'created', 'asc')
    },

    pendings: function () {
      return this.lists.filter(list => !list.done)
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'recipe': function (recipe) {
      if (recipe) this.getList(recipe)
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('actionList', [
      'addList',
      'getList',
      'deleteList'
    ]),
    
    ...mapActions('recipes', [
      'approveRecipe',
    ]),

    validateForm() {
      if (this.$refs.form.validate()) {
        Promise.resolve(
          this.addList(this.recipe)
        )
        .then(() => {
          this.$store.commit('actionList/setData', {})
          this.$refs.form.resetValidation()
        })
      }
    },

    updateValue(list, e) {
      list.ref.update({ done: e })
      list.done = e
    },

    approve() {
      Promise.resolve(this.approveRecipe(this.recipe))
      .then(() => {
        this.$emit('approved')
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (this.recipe) this.getList(this.recipe)
  }
}
</script>